// TODO: function can be removed, only initialization left
// api calls can be done directly in controllers
import Axios from "axios";
import { ObjectId } from "mongoose";
import qs from "qs";

import { API_BASE_URL } from "../config";
import { useSnackbar } from "context/snackbar";

import { IUser, IBooking, IQuickForm } from "navision-proxy-api/@types";
import {
  BookingsRequestQuery,
  BookingsResponse,
  BookingsFormDataResponse,
  QuickFormRequestQuery,
  QuickFormBulkEditRequest,
  ReorderQuickFormRequest,
  IGraphDriveItem,
  LabelsStatusResponse,
} from "navision-proxy-api/@types/api";

import { useHistory } from "react-router-dom";
import { IAuthUser, useAuth } from "context/auth";
import { useLang } from "context/lang";

export type LangCode = "en" | "da" | "de" | "sv" | "nl";
/** Translations used or clients.
 * TODO: get it from navision-proxy-api/@types
 */
export interface ITranslation {
  languageCode: LangCode;
  languageName: string;
  translations: object;
}

/** Used for storing content for pages in markdown language
 *  * TODO: get it from navision-proxy-api/@types
 */
export interface IContent {
  languageCode: LangCode;
  title: string;
  content: string;
}

export function useApi() {
  const user: IUser & { token: string } = JSON.parse(
    localStorage.getItem("user") || "{}"
  );

  const { lang } = useLang();
  const { openSnackbar } = useSnackbar();
  const history = useHistory();

  const axios = Axios.create({
    baseURL: API_BASE_URL,
    headers: {
      "Content-Type": "application/json",
      PrincipalCode: user?.principalCode,
      UserId: user?.userId,
      CompanyName: user?.companyName,
      lang: lang,
      Authorization: `Bearer ${user?.token}`,
    },
    paramsSerializer: (params) => {
      return qs.stringify(params);
    },
  });

  axios.interceptors.response.use(
    (response) => response,
    (error) => {
      console.log("Error from api interceptors.");

      //try to safe parse error response
      let responseData, requestData;
      try {
        if (error?.response) {
          responseData = error.response;
        }
      } catch (e) {
        console.log("Error parsing response  data.");
      }
      try {
        if (error?.request) {
          requestData = JSON.stringify({
            status: error.request.status,
            readyState: error.request.readyState,
            responseText: error.request.responseText,
          });
        }
      } catch (e: any) {
        console.log("Error parsing request data.", e.message);
      }
      const errorMessage = `${error.message} for ${error?.config?.url}, detailed request: ${requestData}. Detailed response: ${responseData}. Is axios error: ${error?.isAxiosError}`;

      console.log(errorMessage);
      if (
        errorMessage.includes("userNotFound") ||
        error?.response?.status == 401
      ) {
        localStorage.clear();
        history.push("/login");
      }

      if (openSnackbar) {
        if (errorMessage.includes("Network Error")) {
          // openSnackbar(
          //   `There is an network error. Please try to refresh the page. ${errorMessage}`,
          //   "error"
          // ); //default error notification
        } else {
          openSnackbar(errorMessage, "error"); //default error notification
        }
      }
      throw new Error(`Axios interceptors error: ${errorMessage}`); //propogate error forward
      //return { data: {} };
    }
  );

  return {
    /** It make more sense to just use axios straight instead of doubling action here */
    axios,
    login: async (username: string, password: string): Promise<IAuthUser> => {
      const { data } = await axios.post("/bookings/login", {
        username,
        password,
      });

      axios.defaults.headers["PrincipalCode"] = data.principalCode;
      axios.defaults.headers["UserId"] = data.userId;
      axios.defaults.headers["CompanyName"] = data.companyName;
      axios.defaults.headers["Authorization"] = `Bearer ${data.token}`;
      return data;
    },

    getUser: async (userId: string): Promise<IUser> => {
      const { data } = await axios.get("/user", { params: { userId } });
      return data;
    },
    saveUserConfig: async (user: Partial<IUser>): Promise<IUser> => {
      const { data } = await axios.post("/bookings/userConfig", user);
      return data;
    },

    fetchBookings: async (
      query: BookingsRequestQuery = { filters: {} }
    ): Promise<BookingsResponse> => {
      const response = await axios.get("/bookings", { params: query });
      return response.data;
    },
    fetchBookingByTrackingNumber: async (
      trackingNumber: string
    ): Promise<IBooking> => {
      const response = await axios.get(
        `/trackBooking?trackingNumber=${trackingNumber}`
      );
      return response.data;
    },
    fetchBookingFormData: async (): Promise<BookingsFormDataResponse> => {
      const { data } = await axios.get("/bookingFormData");
      return data;
    },

    fetchGuidelines: async (): Promise<IGraphDriveItem[]> => {
      const { data } = await axios.get("/guidelines");
      return data;
    },
    createBooking: async (booking: IBooking) => {
      const { data } = await axios.post("/booking", booking);
      return data;
    },

    createBookings: async (bookings: IBooking[]) => {
      const { data } = await axios.post("/bookings", bookings);
      return data;
    },

    updateBooking: async (booking: IBooking) => {
      const { data } = await axios.put("/booking", booking);
      return data;
    },

    deleteBooking: async (booking: IBooking) => {
      const { data } = await axios.post(`/deleteBooking`, booking);
      return data;
    },

    deleteQuickFormBooking: async (id: ObjectId) => {
      const { data } = await axios.delete(`/booking?id=${id}`);
      return data;
    },

    createQuickForm: async (quickForm: IQuickForm): Promise<IQuickForm> => {
      const { data } = await axios.post(`/quickForm`, quickForm);
      return data;
    },

    deleteQuickForm: async (id: ObjectId) => {
      await axios.delete(`/quickForm?quickFormId=${id}`);
    },

    releaseQuickForm: async (id: ObjectId) => {
      const { data } = await axios.post(`/releaseQuickForm`, { id });
      return data;
    },

    fetchQuickForms: async (
      query: QuickFormRequestQuery = { filters: {} }
    ): Promise<IQuickForm[]> => {
      const { data } = await axios.get(`/quickForms`, { params: query });
      return data;
    },

    updateQuickForm: async ({
      id,
      name,
      editedBookings = [],
    }: QuickFormBulkEditRequest) => {
      const { data } = await axios.put(`/quickForm`, {
        id,
        name,
        editedBookings,
      });
      return data;
    },

    checkPickupDate: async (
      pickupDate: string,
      deliveryCountry: string,
      userId: string
    ): Promise<{ AfterDeadline: boolean }> => {
      const { data } = await axios.post(`/forwardToNav`, {
        url: "/BookingWebAfterDeadline",
        data: {
          User: userId,
          UnloadCountry: deliveryCountry,
          PickupDate: pickupDate,
        },
        method: "post",
      });
      return data;
    },

    getBookingPdf: async (bookingId: string) => {
      const { data } = await axios.post("/forwardToNav", {
        url: `/BookingWebWaybill(${bookingId})`,
        method: "get",
      });
      return data.Pdf;
    },

    reorderQuickFormLines: async (
      reorderLinesRequest: ReorderQuickFormRequest
    ) => {
      await axios.post(`/reorderQuickForm`, reorderLinesRequest);
    },

    getRFIDLabels: async (): Promise<LabelsStatusResponse> => {
      const { data } = await axios.get("/labels");
      return data;
    },

    orderRFIDLabels: async (
      tourtime: string,
      pickupCode: string,
      receiverCode: string,
      amount: number
    ): Promise<LabelsStatusResponse> => {
      const { data } = await axios.post(
        "/labels",
        {},
        {
          params: {
            pickup: pickupCode,
            receiver: receiverCode,
            trip_time: tourtime,
            count: amount,
          },
        }
      );
      return data;
    },
    fetchTranslations: async (lang: string): Promise<ITranslation[]> => {
      const { data } = await axios.get(`/translations?lang=${lang}`);
      return data;
    },
    fetchHelp: async (lang: string): Promise<IContent[]> => {
      const { data } = await axios.get(`/help?lang=${lang}`);
      return data;
    },
  };
}
