import { Box } from "@material-ui/core";
import { useAuth } from "context/auth";

export default () => {
  const { user } = useAuth();
  console.log(user);
  return (
    <Box height={"calc(100vh - 100px)"} width="100%">
      <iframe
        width="100%"
        height="100%"
        src={(user as any)?.reportLink}
        frameBorder={0}
        allowFullScreen
      ></iframe>
    </Box>
  );
};
