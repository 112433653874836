import React from "react";
import { BookingForm } from "../components/BookingForm";
import _ from "lodash";
import moment from "moment";

import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";

import { useFieldsSettings } from "context/fieldsSettings";
import { IBooking, BookingFieldId } from "navision-proxy-api/@types";
import { Validator } from "utils/validators";
import { useHistory } from "react-router-dom";
import { useQuickForm } from "hooks/quickForm";
import { useBookingForm } from "hooks/bookings/bookingForm";
import { useStore } from "context/store";
import { useLang } from "context/lang";
import { useBookingActions } from "hooks/bookings/bookingActions";
import { useSnackbar } from "context/snackbar";

import { ChooseMenu, ChooseMenuItem } from "components/ChooseMenu";
import { FieldsMenu } from "components/FieldsMenu";
import BookingsList from "containers/BookingsList";
import { Loader } from "components/Loader";
import { useAuth } from "context/auth";
import { useBookings } from "hooks/bookings";

interface IProps {
  defaultBookingParams?: Partial<IBooking>;
  hideToolbar?: boolean;
  showBookingsList?: boolean;
  ignoredFields: BookingFieldId[];
  validators: { [fieldName in BookingFieldId]?: Validator[] };
  /** Show input fields as full width */
  fullWidth: boolean;
}

export default ({
  showBookingsList,
  hideToolbar,
  defaultBookingParams = {},
  ignoredFields,
  validators,
  fullWidth,
}: IProps) => {
  const [listKey, setListKey] = React.useState(Date.now()); //state used for triggering of rerendre of bookings list component
  const history = useHistory();
  const { hiddenFields, toogleHiddenField } = useFieldsSettings();

  const { quickForms, loading: loadingQuickForm } = useQuickForm({
    isLocal: Boolean(defaultBookingParams.isLocal),
  });

  const { user } = useAuth();

  const {
    booking,
    setBooking,
    enableCCLookup,
    setEnableCCLookup,
    pushCustomersCustomer,
    initBookingForm,
    customersCustomerLookup,
    handleClearAddresses,
    errors,
    setErrors,
    handleChangeField,
  } = useBookingForm();

  const { createBooking, loading } = useBookingActions();

  const {
    templateModeProps,
    setTemplateModeProps,
    bookingFormData: { fields },
    loading: loadingBookingData,
  } = useStore();

  const { openSnackbar } = useSnackbar();

  const { t } = useLang();

  const { reloadBookings } = useBookings();

  React.useEffect(() => {
    // if (!hideToolbar) {
    //   loadQuickForms({ isLocal: Boolean(defaultBookingParams.isLocal) });
    // }
    // if (!enableCCLookup) {
    //   setEnableCCLookup(true);
    // }
    // if (!pathname.includes("edit") && !templateModeProps) {
    //   initBookingForm();
    // }
    // if (templateModeProps) {
    //   //turn off template mode after switching to create booking page
    //   setTemplateModeProps(false);
    // }
    return () => {
      setErrors({} as Record<BookingFieldId, string>);

      if (templateModeProps) {
        setTemplateModeProps(null);
      } else {
        initBookingForm();
      }
    };
  }, []);

  const handleUseTemplate = () => {
    setTemplateModeProps({
      isLocal: defaultBookingParams.isLocal,
    });
    history.push("/bookings");
  };

  const validate = () => {
    const newErrors = {} as Record<BookingFieldId, string>;

    Object.keys(validators).forEach((field) => {
      const fieldValidator = validators[field as BookingFieldId];
      if (fieldValidator) {
        for (let validator of fieldValidator) {
          const error = validator(booking[field as keyof IBooking]);
          if (error) {
            newErrors[field as keyof IBooking] = t(error);
            break;
          }
        }
      }
    });

    //TODO change to generic validator
    if (booking["pickupDate"] && booking["deliveryDate"]) {
      const deliveryDate = moment(booking["deliveryDate"]).utc(true);
      const pickupDate = moment(booking["pickupDate"]).utc(true);

      if (pickupDate.isAfter(deliveryDate, "days")) {
        newErrors["deliveryDate"] = t("errorPickupLaterDelivery");
      } else if (
        pickupDate.isSame(deliveryDate, "days") &&
        booking["deliveryTime"] &&
        booking["pickupTime"] &&
        parseInt(booking["deliveryTime"].split(":")[0]) <
          parseInt(booking["pickupTime"].split(":")[0])
      ) {
        newErrors["deliveryDate"] = t("errorPickupLaterDelivery");
      }
    }

    setErrors(newErrors);
    console.log(newErrors);
    const isNotErrored = _.isEmpty(newErrors);
    if (!isNotErrored) {
      openSnackbar(Object.values(newErrors).join(","), "error");

      Object.keys(newErrors).forEach(
        (key) =>
          hiddenFields.includes(key as BookingFieldId) &&
          toogleHiddenField(key as BookingFieldId)
      );
    }

    return isNotErrored;
  };

  const handleSubmit = async () => {
    if (validate()) {
      //validated booking

      let newBooking = {
        ...booking,
        ...defaultBookingParams,
        deliveryDate: booking.deliveryDate
          ? moment(booking.deliveryDate).utc(true).toDate()
          : undefined,
        latestDeliveryDate: booking.latestDeliveryDate
          ? moment(booking.latestDeliveryDate).utc(true).toDate()
          : undefined,
        pickupDate: booking.pickupDate
          ? moment(booking.pickupDate).utc(true).toDate()
          : undefined,
        isLocal: Boolean(defaultBookingParams.isLocal),
        customersCustomer: enableCCLookup
          ? String(customersCustomerLookup[0]?.id)
          : "",
        customerNb: enableCCLookup
          ? customersCustomerLookup[0]?.ccNumber
          : booking.customerNb,
        movementStatus: "", //cleanup
      } as IBooking; // is already validated;

      //validate goods
      if (newBooking?.goods?.some((good) => !good[1] || good[1] == "0")) {
        openSnackbar(t("goodIsEmpty"), "error");
        return;
      }

      if (newBooking.quickForm) {
        //create two bookings
        await createBooking({ ...newBooking, quickForm: undefined });
      }
      await createBooking(newBooking);

      //save latest pickup info
      if (
        user?.appConfig?.createBooking?.savePickupDate &&
        newBooking.pickupDate
      ) {
        localStorage.setItem(
          "latestPickupDate",
          newBooking.pickupDate.toDateString()
        );
      }
      if (
        user?.appConfig?.createBooking?.savePickupTime &&
        newBooking.pickupTime
      ) {
        localStorage.setItem("latestPickupTime", newBooking.pickupTime);
      }
      //if we were editing
      if (templateModeProps) {
        setTemplateModeProps(null);
      } else {
        initBookingForm();
      }
      setListKey(Date.now());
    }
  };

  const today = new Date();
  today.setSeconds(0, 0); // loading bookings rerenders this which leads to infinte loop because today filter always updating

  return (
    <>
      {!hideToolbar && (
        <Box clone pb={2}>
          <Grid item xs={12}>
            <Divider />
            <Grid item xs={12} container justify="space-between">
              <Grid
                item
                xs={6}
                sm={8}
                container
                justify="flex-start"
                alignItems="center"
              >
                <Box m={1}>
                  <Button
                    id="use-template"
                    size="small"
                    color="default"
                    variant="contained"
                    onClick={handleUseTemplate}
                  >
                    {t("useTemplate")}
                  </Button>
                </Box>
                <Box m={1}>
                  <ChooseMenu
                    variant="contained"
                    color="default"
                    size="small"
                    prefix={t("addToQuickForm")}
                    items={quickForms.map<ChooseMenuItem>(
                      ({ _id, name, type }) => ({
                        key: String(_id),
                        value: name,
                      })
                    )}
                    currentItem={{
                      key: String(booking.quickForm),
                      value:
                        quickForms.find(({ _id }) => _id === booking.quickForm)
                          ?.name || "",
                    }}
                    isLoading={Boolean(loadingQuickForm["load"])}
                    handleChoose={(item) =>
                      handleChangeField("quickForm", item.key)
                    }
                    clearable
                  />
                </Box>
              </Grid>
              <Grid item xs={6} sm={4}>
                <Box
                  display="flex"
                  alignItems="center"
                  height="100%"
                  justifyContent="end"
                >
                  <FieldsMenu
                    size="small"
                    options={fields
                      .filter(
                        ({ id, required, readOnly, isPackage }) =>
                          !required &&
                          !readOnly &&
                          !isPackage &&
                          !ignoredFields.includes(id)
                      )
                      .map(({ id }) => ({
                        id,
                        title: t(id),
                        value: !hiddenFields.includes(id),
                      }))}
                    onChoose={({ id }) =>
                      toogleHiddenField(id as BookingFieldId)
                    }
                  />
                </Box>
              </Grid>
            </Grid>
            <Divider />
          </Grid>
        </Box>
      )}
      <Loader
        color="primary"
        size={50}
        margin={10}
        loading={Boolean(loadingBookingData["load"])}
      >
        <>
          <BookingForm
            pushCustomersCustomer={pushCustomersCustomer}
            setBooking={setBooking}
            setEnableCCLookup={setEnableCCLookup}
            loading={Boolean(loading["create"])}
            errors={errors}
            handleSubmit={handleSubmit}
            fullWidth={fullWidth}
            ignoredFields={ignoredFields}
            booking={booking}
            handleChangeField={handleChangeField}
            customersCustomerLookup={customersCustomerLookup}
            handleClearAddresses={handleClearAddresses}
          />
          {showBookingsList && (
            <BookingsList
              key={listKey}
              initQuery={{
                filters: {
                  isLocal: defaultBookingParams.isLocal,
                  pickupDate: { ge: today.toISOString() },
                },
              }}
            />
          )}
        </>
      </Loader>
    </>
  );
};
